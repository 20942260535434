require('leaflet');
require('polyline-encoded');

var markers = []; 
var polylines = [];
var polystr = [];
var map; 
var info;       
var points = [];   
var instructions = [];
var routes = [];
var pendingRequest = false;
var vUrlNearest;
var vUrlDirection;
var vUrlGpx;
var vUrlGpxExport;
var vId;
var popOpen = false;

$(document).ready(function()
{            
  PositionnerMap();
  initialize();  
  $("#chkbike").change(() => {
    switchProfile();
    getPath();
  });
  GetActualPosition();
});

window.setId = function(pId)
{
  vId = pId;
}

window.setUrlNearest = function(pUrl)
{
  vUrlNearest = pUrl;
}

window.setUrlDirection = function(pUrl)
{
  vUrlDirection = pUrl;
}

window.setUrlGpx = function(pUrl)
{
  vUrlGpx = pUrl;
}

window.setUrlGpxExport = function(pUrl)
{
  vUrlGpxExport = pUrl;
}

window.debugLibrary = function()
{ 
   
}

function refreshRoute()
{ 
  $("#infos").hide();
  $("#buttons").hide();
  if(points.length>0)
  {
    $("#buttons").show();
  } 
  for(i=0;i<polylines.length;i++)
  {
    polylines[i].addTo(map);
  }  
  for(i=0;i<points.length;i++)
  { 
    DisplayMarkerNum(points[i].latitude,points[i].longitude, i+1);     
  } 
  DisplayGpxButton();
  DisplayDistanceAndTime();
  DisplayInstructions();
  CentrerCarte();
}

function DisplayDistanceAndTime()
{
  let iTime = 0;
  let iDistance = 0;

  for(i=0;i<routes.length;i++)
  { 
    let tRoute = routes[i];
    iTime+=tRoute.duration;
    iDistance+=tRoute.distance;
  } 

  let distance =  (iDistance / 1000).toFixed(1) + " km"; 
  let temps =  formatTime(iTime);
  $("li#distance").html(distance);
  $("li#time").html(temps);
  if(iDistance>0)
  {
    $("#infos").show();
  }
  
}

function DisplayGpxButton()
{
  if(points.length>1)
  {
    $("#btn_gpx").show();
  } 
  else
  {
    $("#btn_gpx").hide();
  }
}

function DisplayInstructions()
{
  let vInstructions = "<ul>"; 
 
  for(i=0;i<instructions.length;i++)
  { 
    for(j=0;j<instructions[i].length;j++)
    {
      if(instructions[i][j]!="")
      {
        vInstructions+="<li>" + instructions[i][j] + "</li>";
      }
    }
     
  }  
  vInstructions += "</ul>"; 
  $("#instructions").html(vInstructions);
}

window.removePoint = function() {
  if(points.length>0)
  {
    Clear();
    polylines = [];
    polystr = [];
    instructions = [];
    routes = [];       
    points.pop();
    getPath();
  }
}

window.deletePoint  = (id) => {
  if(points.length>0)
    {
      Clear();
      polylines = [];
      polystr = [];
      instructions = [];
      routes = [];    
      points.splice(id-1,1);
      getPath();
    }
}

window.switchProfile = () => {
  if(points.length>0)
  {    
    Clear();   
    polylines = [];
    polystr = [];
    instructions = [];
    routes = [];       
  }
}


function initialize() 
{
  map = L.map('map').setView([vLatitude, vLongitude],vZoom);
    L.tileLayer('https://tiles.kletellier.xyz/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map); 
 
  map.on('popupopen', onPopupOpen);
  map.on('popupclose', onPopupClose);   
  map.on('click',onMapClick);
}


function PositionnerMap()
{          
  var vWidth  = $(window).width();
  var vHeight = $(window).height();

  var vW = (vWidth - 50) * vWidthFactor ;
  var vH = (vHeight - 110) * vHeightFactor;  
  $("#map").css("height",vH + "px");   
  $("#map").css("width",vW + "px");
}
 

function CentrerCarte()
{
  if(polylines.length>0)
  {
    var group = new L.featureGroup(polylines);
    map.fitBounds(group.getBounds());
  }  
  else
  {
    if(points.length==0)
    {
      map.setView([vLatitude,vLongitude],15);
    }     
  }
}

window.Centrer = function(lat,lng,zoom) {
  vLatitude = lat;
  vLongitude = lng;
  map.setView([vLatitude,vLongitude],zoom);
}

function onMapClick(e)
{
  let pLatLng = e.latlng;
  let pLat = pLatLng.lat;
  let pLon = pLatLng.lng;
  getNearest(pLat,pLon);
}

function getPath()
{
  var vData = {};
  
  vData.coordinates = points.map((pt) => {
    return pt.latitude + "," + pt.longitude;
  }).join(";");
 
  if($("#chkbike").is(":checked"))
  {
    vData.profile = "biking";
  }
  if($("#chkwalk").is(":checked"))
  {
    vData.profile = "walking";
  }

  if(!pendingRequest)
  {
    pendingRequest = true;
    $.ajax({
        type: "POST",
        url: vUrlDirection,
        data: vData, 
        success: ajaxOnSuccessPath,
        error: ajaxOnFailure
    });
  }
}
 

function fixNearest(lat,lng,marker)
{
    var vData = {};
    vData.lat = lat;
    vData.lng = lng; 
    if($("#chkbike").is(":checked"))
    {
      vData.profile = "biking";
    }

    if($("#chkwalk").is(":checked"))
      {
        vData.profile = "walking";
      }

    if(!pendingRequest)
    {
      pendingRequest = true;
      $.ajax({
          type: "POST",
          url: vUrlNearest,
          data: vData, 
          success: (response) => {
            let mid= marker.options.id;             
            marker.setLatLng(new L.LatLng(response.latitude, response.longitude),{draggable:'true'}); 
            pendingRequest = false;
            points[mid-1] = response;
            refreshPath();
          },
          error: ajaxOnFailure
      });
    }  
}

function getNearest(lat,lng)
{          
    var vData = {};
    vData.lat = lat;
    vData.lng = lng; 
    if($("#chkbike").is(":checked"))
    {
      vData.profile = "biking";
    }

    if($("#chkwalk").is(":checked"))
      {
        vData.profile = "walking";
      }

    if(!pendingRequest)
    {
      pendingRequest = true;
      $.ajax({
          type: "POST",
          url: vUrlNearest,
          data: vData, 
          success: ajaxOnSuccessNearest,
          error: ajaxOnFailure
      });
    }  
}

function getGpx()
{
    var vData = {};
    vData.polylines = polystr; 
    vData.points = points;
    vData.id = vId;
    $.ajax({
        type: "POST",
        url: vUrlGpx,
        data: vData, 
        success: ajaxOnSuccessGpx,
        error: ajaxOnFailure
    });     
}

function ajaxOnSuccessGpx(response)
{ 
  if(response.code=="ok")
  {
    DisplayGpxButton();
  }
}

function ajaxOnSuccessPath(response)
{
  instructions = [];
  pendingRequest = false;
  if(response.routes!=undefined)
  {
    if(response.routes.length>0)
    {   
      instructions = response.itineraires.map((itineraire) => {
        if(itineraire.length > 0)
        {
            return itineraire.map((item) => item) ;
        }
        else
        {
            return [];
        }                        
    }) ; 
       let poly = L.Polyline.fromEncoded(response.routes[0].geometry);

        Clear();   
        polylines = [];
        polystr = [];
        
        routes = [];       

       polystr.push(response.routes[0].geometry);
       polylines.push(poly);
       //instructions.push(instruction);
       routes.push(response.routes[0]);

       getGpx();
    }
  }
  refreshRoute(); 
}
 

function ajaxOnSuccessNearest(response) { 
  pendingRequest = false;
  points.push(response);
  refreshPath();
}

function refreshPath()
{
  if(points.length > 1)
  { 
    getPath(); 
  }
  else
  {
    refreshRoute(); 
  }  
}

function ajaxOnFailure(response){
  pendingRequest = false;
}

function onPopupOpen(e) 
{
  popOpen = true;
}

function onPopupClose(e) 
{
  popOpen = false;
}   

window.Clear = function()
{  
  polylines.forEach(function (item) {
    map.removeLayer(item)
  });
  markers.forEach(function (item) {
    map.removeLayer(item)
  });
  
  
}
 

function getIconNumber(number)
{
 var marker = L.icon({
  iconUrl: vMarkerNumUrl + number,  
     iconSize:     [30, 43], // size of the icon
    iconAnchor:   [15, 43] // point of the icon which will correspond to marker's location
  });
 return marker;
}
 

function DisplayMarkerNum(lat,lon,num)
{
 var icon = getIconNumber(num);
 var marker = L.marker([lat,lon],{icon:icon,draggable:true,id:num}).addTo(map);  
 var popup = L.popup({maxWidth:400,maxHeight:400}) ;
 let txt = "<p><a href='javascript:deletePoint(" + num + ")'>Supprimer</a></p>";
 popup.setContent(txt);
 marker.bindPopup(popup);
 marker.on('dragend', function(event){
    var marker = event.target;
    let position = marker.getLatLng();
    fixNearest(position.lat, position.lng,marker);
    
  });
   
 markers.push(marker); 
}
 
 
function GetActualPosition()
{     
  navigator.geolocation.getCurrentPosition((pos) => { 
      const crd = pos.coords;
    vLatitude = crd.latitude;
    vLongitude = crd.longitude;
    CentrerCarte()
  }, (err) => { }, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  });
}
